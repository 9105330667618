body {
  font-family: $mulish-semi-bold;
}

* {
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
