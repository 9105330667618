$color_1: darkgrey;
$color_2: #000;
$color_3: red;
$color_4: #0b7677;
$background-color_1: #fff;
$background-color_2: #3ebec8;
$background-color_3: lightblue;
$background-color_4: #eee;
$background-color_5: #0075ff;
$background-color_6: #c7e7f5;
$background-color_7: green;
$border-color_1: #3ebec8;
$border-color_2: 'black';
$border-color_3: #0075ff;

/* CustomCheckbox.css */
.main_a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.mainHeading {
  display: flex;
  align-self: center;
  font-weight: 400;
  font-size: 18;
}

.onboard_main_a {
  display: flex;
  width: 100%;
}

.stepIndicator {
  width: 20%;
  padding: 20px;
}

.formRight {
  width: 75%;
}

.label_m {
  width: 100%;
  padding: 30px;
  background-color: $background-color_1;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.form_labe2 {
  width: 100%;
}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.name_input_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name_input {
  width: 100%;
}

.custom-max-size {
  max-width: 700px;
  max-height: 300px;
}

.reference {
  display: flex;
  flex-direction: row;
  font-size: 13;
  font-weight: 500;
  color: $color_1;
}

.upload-button {
  background-color: $background-color_2;
  border-color: $border-color_1;
}

.imageBottomSpace {
  padding-bottom: 15;
}

.upload_icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.adhar-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-icon1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.nameInputDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.availableArea {
  display: flex;
  flex-direction: row;
  font-size: 13;
  font-weight: 400;
}

.otp_container {
  display: flex;
  justify-content: center;
  align-self: 'center';
}

.listStyle {
  flex-direction: row;
  align-self: center;
  overflow: hidden;
  border-top-left-radius: 10;
  border-top-left-radius: 10;
}

.title {
  border-color: $border-color_2;
  border-width: 0.5;
  font-size: 14;
  text-align: 'center';
  padding: 8;
  height: 55;
}

.horizontal-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list-item {
  color: $color_2;
  align-self: center;
  border-radius: 5px;
  width: 10;
}

.mainList {
  background-color: $background-color_3;
  padding: 5;
}

.post {
  display: flex;
  flex-direction: row;
  background-color: $background-color_4;
  margin-top: 1rem;
  padding-top: 1rem;
  align-self: center;
  width: 100%;
}

.viewButton {
  border-radius: 5;
  align-self: flex-end;
  width: 100px;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-icon {
  width: 20px;
  height: 20px;
  border: 2px solid #0075ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-weight: bold;
}

.checked {
  .checkbox-icon {
    background-color: $background-color_5;
    border-color: $border-color_3;
  }
}

.checkbox-label {
  font-size: 16px;
}

.selected-row {
  background-color: $background-color_6;
}

.ant-image-preview-img {
  width: 800px;
  height: 500px;
  vertical-align: middle;
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  user-select: none;
  pointer-events: auto;
  margin: auto;
}

.ant-image-preview-img-wrapper {
  &::before {
    height: 10%;
    max-width: 50%;
    max-height: 50%;

    img {
      max-width: 50%;
      max-height: 50%;
    }
  }
}

.main_epp_err {
  display: flex;
  width: 1050px;
}

.formright_epp_err {
  width: 100%;
  margin-top: 20;
}

.contentStyle {
  text-align: center;
  color: $color_3;
  background-color: $background-color_7;
  border-radius: 20;
  margin-top: 16;
}

.box-form-bor {
  padding: 15px;
  // border: solid 2px #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.custom-text-sm {
  font-size: small;
  color: gray;
}

.div-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.contract_des {
  width: 100%;
  float: left;
  font-family: Calibri;
}

.mide_data_t {
  width: 100%;
  float: left;
}

.mide_data_t p {
  line-height: 24px;
}

.mide_data_t ol li {
  line-height: 26px;
}

.top_heading {
  width: 100%;
  text-align: center;
  padding: 23px 0px;
  font-size: x-large;
}

.signatureCanvas {
  height: 100%;
  width: 100%;
}

.signatureBox {
  // border: 1px solid #0B7677;
  padding: 20px;
  // max-width: 600px;
}

.signatureDottedBox {
  border: 1px dashed #0b7677;
  height: 350px;
  // width: 900px;
  border-width: 2px;
  border-radius: 10px;
  margin: 0;
  // margin-right: 100px;
}

.clearSignature {
  text-align: center;
  color: #0b7677;
  padding: 15px 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 17px;
}

.buttonColor {
  background-color: #0b7677;
  border-color: #0b7677;
  color: #fff;
}

.mide_data_t_ li {
  line-height: 26px;
  list-style: auto;
}

.ant-image-preview-body {
  top: -75% !important;
}

// .ant-picker-range .ant-picker-input {
//     display: none !important;
// }
.ant-picker-range .ant-picker-range-separator {
  display: none !important;
}
.ant-picker-range .ant-picker-suffix {
  justify-content: end !important;
}

.ant-picker-range .ant-picker-suffix {
  display: none !important;
}

.clickable-row:hover {
    cursor: pointer;
}
