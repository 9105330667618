.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.fade-in-appear {
  opacity: 0;
}

.fade-in-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  text-transform: capitalize;
}
